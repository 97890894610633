* {
  transition: color 0.2s, background-color 0.2s;
}

body {
  margin: 0;
  margin-top: 48px;
  color: var(--black-2);
  background-color: var(--body-bg);
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a:link {
  color: var(--link);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: none;
}

a:visited {
  color: var(--link);
  text-decoration: none;
}

.container {
  max-width: 980px;
  margin: auto;
  padding: 0 16px;
}

.section-title {
  font-size: 32px;
  line-height: 1.125;
  font-weight: 700;
  letter-spacing: .004em;
  margin-bottom: 24px;
  color: var(--card-title);
}

.theme-command {
  display: none;
}