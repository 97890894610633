.gallery-info {
  padding: 40px 40px 0 40px;
}

.gallery-info > div {
  padding-top: 30px;
}

.gallery-tags > div {
  margin-right: 10px;
}

.gallery-pages {
  margin-bottom: 80px;
  /* background-color: var(--variant-softBg); */
}

.gallery-page {
  margin-top: 100px;
  margin-bottom: 100px;
}

.gallery-page:first-child {
  margin-top: 90px;
}

.gallery-page-num {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

/* 
.root-mobile .gallery-page {
  margin-bottom: 200px;
} */

.gallery-container {
  width: 100%;
  height: 100%;
}

.gallery-half-page {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}