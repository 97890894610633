body[data-theme="light"] {
  --white-opacity-8: rgba(255, 255, 255, .8);
  --white-1-opacity-8: rgba(229.5, 229.5, 229.5, .8);
  --white: #fff;
  --white-1: rgb(229.5, 229.5, 229.5);
  --white-2: rgb(204, 204, 204);

  --grey: rgb(127.5, 127.5, 127.5);

  --black: #000;
  --black-2: rgb(51, 51, 51);
  --black-4: rgb(102, 102, 102);

  --border-color: #8882;

  --link: #06c;

  --blur: blur(32px);

  --blue: #0071e3;
  --white-txt-on-blue-bg: #fff;

  --nav-bg: var(--white-opacity-8);

  --footer-link-grey: #515154;

  --body-bg: #fff;
  --card-bg: #fff;
  --card-title: #1d1d1f;
  --card-time: #6e6e73;
  --date-txt: #6e6e73;

  --nav-arrow: #1d1d1f;
  --variant-softBg: #f9f9f9;
  --joy-palette-background-surface: #f9f9f9;
  --variant-outlinedColor: #1d1d1f;
  --joy-fontSize-md: 14px;
  --Avatar-size: 1.6rem;
  --joy-fontWeight-md: bold;

  /* ant design */
  --menu-selected-bg: #d9d9d9;
}

@media (prefers-theme: dark) {
  body[data-theme="auto"] {
    --white-opacity-8: rgba(0, 0, 0, .8);
    --white-1-opacity-8: rgba(25.5, 25.5, 25.5, .8);
    --white: #000;
    --white-1: rgb(25.5, 25.5, 25.5);
    --white-2: rgb(51, 51, 51);

    --black: #fff;
    --black-2: rgb(204, 204, 204);
    --black-4: rgb(153, 153, 153);

    --border-color: #8884;

    --link: #5fafff;

    --blur: blur(32px);

    --blue: #00376f;
    --white-txt-on-blue-bg: #c0dfff;

    --nav-bg: rgba(0, 0, 0, .5);

    --footer-link-grey: #a1a1a6;

    --body-bg: #101010;
    --card-bg: #181818;
    --card-title: #cfcfd5;
    --card-time: #6e6e73;
    --date-txt: #6e6e73;

    --nav-arrow: #e5e5ee;
    --variant-softBg: #191919;
  
    /* ant design */
    --menu-selected-bg: #6e6e6e4f;
  }
}

body[data-theme="auto"],
body[data-theme="dark"] {
  --white-opacity-8: rgba(0, 0, 0, .8);
  --white-1-opacity-8: rgba(25.5, 25.5, 25.5, .8);
  --white: #000;
  --white-1: rgb(25.5, 25.5, 25.5);
  --white-2: rgb(51, 51, 51);

  --black: #fff;
  --black-2: rgb(204, 204, 204);
  --black-4: rgb(153, 153, 153);

  --border-color: #8884;

  --link: #5fafff;

  --blur: blur(32px);

  --blue: #00376f;
  --white-txt-on-blue-bg: #c0dfff;

  --nav-bg: rgba(0, 0, 0, .5);

  --footer-link-grey: #a1a1a6;

  --body-bg: #101010;
  --card-bg: #181818;
  --card-title: #cfcfd5;
  --card-time: #6e6e73;
  --date-txt: #6e6e73;

  --nav-arrow: #e5e5ee;
  --variant-softBg: #191919;
  --joy-palette-background-surface: #191919;
  --variant-outlinedColor: #cfcfd5;
  --joy-fontSize-md: 14px;
  --Avatar-size: 1.6rem;
  --joy-fontWeight-md: bold;

  /* ant design */
  --menu-selected-bg: #6e6e6e4f;
}