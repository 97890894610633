.ant-menu-inline {
  border-inline-end: unset !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: var(--black-2) !important;
}

.ant-menu-item-selected {
  background-color: var(--menu-selected-bg) !important;
  color: var(--black-2) !important;
}

.ant-menu-item {
  box-shadow: none !important;
}

.ant-menu-submenu::after, .ant-menu-submenu-active::after {
  border-width: 0px !important;
}

.ant-menu-submenu-selected::after {
  border-width: 2px !important;
  border-color: #222 !important;
  text-decoration: none !important;
}

.ant-menu-title-content {
  text-align: left !important;
}

.ant-menu-title-content a {
  text-decoration: none !important;
}

.ant-menu, .ant-menu-submenu {
  background-color: transparent !important;
}

.ant-menu-submenu-popup {
  background-color: var(--body-bg) !important;
}

.ant-menu, .ant-menu-submenu, .ant-menu-submenu-popup, .ant-menu-submenu-selected::after, .ant-menu-title-content {
  color: var(--black-2) !important;
}

.ant-float-btn-icon {
  margin-top: 9px !important;
}

.ant-float-btn-primary .ant-float-btn-body {
  background-color: #666 !important;
}

.ant-float-btn-primary .ant-float-btn-icon {
  color: #000 !important;
}